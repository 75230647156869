<template>
  <WeSelect
    v-model="computedData"
    v-bind:options="options"
    label-prop="name"
    v-bind:small="true"
    v-bind:form-control="false"
  />
</template>
<script>
export default {
  name: "TableSelect",
  data() {
    return {
      data: this.value,
    };
  },
  props: {
    value: {
      default: () => false,
    },
    row: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    componentId() {
      return (
        "wetable-content-row-cell-select-" + helper.getRandomInteger(1, 999999)
      );
    },
    computedData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        const data = {
          value: value,
          row: this.row,
          old: this.value,
        };

        this.$parent.$parent.$parent.$emit("on-select", data);
      },
    },
  },
  methods: {
    setSwitchValue(e) {
      const value = e.target.checked;

      let data = {
        id: this.row.id,
        is_active: value,
      };

      this.$parent.$parent.$parent.$emit("on-switch", data);

      this.setSwitchStatus(e.target.checked);
    },
  },
};
</script>
